<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="container">
      <div class="column spacer">
        <h1>Alle Domeinnaam-extensies</h1>
        <h2>Meest populaire domeinnaam-extensies (TLD's) die wij aanbieden</h2>
        <Prices :items="data.staticData.prices.mostPopularDomainNameExtensionsPrices"/>
        <div>
          <p>
            Een domeinnaam bestaat uit een paar verschillende onderdelen. Er is sprake van
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/domeinnamen.png"
              height="251"
              width="300"
              title="nederlandse domeinen"
              alt="nederlandse domeinen"
            >
            ‘www’ of een eventueel subdomein, vervolgens de domeinnaam zelf en daarna een extensie. Dit noemen we het Top-Level-Domain, de TLD.
          </p>
          <p>U kunt bij Hosting on Demand alle extensies registreren. Dat betekent dat u Nederlandse domeinen kunt vastleggen, net als .de-domeinen en domeinnamen met alle andere extensies. Uiteraard gelden hiervoor <router-Link to="/domeinnamen/prijzen-domeinnamen/">verschillende prijzen</router-Link>, afhankelijk van de extensie die u wilt vastleggen.</p>
          <p>Een .eu-domeinnaam is de goedkoopste optie, geschikt voor in heel de EU. Het grote voordeel van deze extensie is dat er nog relatief veel domeinnamen beschikbaar zijn. De populariteit neemt pas de laatste jaren flink toe, waardoor er minder domeinen geregistreerd zijn dan met de Nederlandse extensie.</p>
          <h3>Registreer Nederlandse domeinen</h3>
          <p>Een van de Nederlandse domeinen registreren? Dat kan al voor €9,00 per jaar, waardoor u daarvoor slechts dan €0,75 per maand betaalt. U dient het domein altijd voor minimaal 1 jaar tijd te registreren. Vervolgens heeft u jaarlijks de mogelijkheid om het domein te verlengen, zodat u daar eigenaar van blijft.</p>
          <p>Uiteraard bieden we de mogelijkheid om de Nederlandse domeinen te registreren of andere domeinen te verhuizen, zodat u die kunt gebruiken in combinatie met de pakketten voor webhosting. Houd er rekening mee dat het niet mogelijk is om de domeinen zelf te verhandelen, u kunt die uitsluitend registreren om er zelf gebruik van te maken of die (tijdelijk) te parkeren indien u daar geen hosting bij nodig heeft.</p>
          <h3>Duitse en andere domeinnamen</h3>
          <p>Er zijn daarnaast diverse andere extensies beschikbaar voor de domeinnamen. Dat betekent dat u bijvoorbeeld de .de-domeinen kunt vastleggen, net als de bekende .com-domeinnamen en websites met extensies van andere landen ter wereld. Het is geen probleem om gewoon vanuit Nederland een domein met een andere extensie te registreren, wij maken dat zo snel mogelijk voor u in orde.</p>
          <h3>Alle extensies voor alle domeinen</h3>
          <p>We zijn gespecialiseerd in alle extensies voor alle domeinnamen. Wilt u verschillende extensies registreren zoals de <router-link to="/domeinnamen/alle-domeinnaam-extensies/net-uk/">.net.uk-versie</router-link>? We zorgen ervoor dat die zo snel mogelijk op uw naam staan, bijvoorbeeld om er met uw onderneming voor te zorgen dat u alle extensies in uw bezit heeft.</p>
          <p>We kunnen alle extensies voor alle domeinnamen registreren. Kunt u de extensie die u zoekt niet vinden of heeft u daar vragen over? Neem contact met ons op, dan vertellen we u graag meer over de mogelijkheden.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Prices from '@/components/Prices.vue';

export default {
  name: 'AllDomainNameExtensions',
  components: {
    Breadcrumbs,
    Prices,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    }
  },
}
</script>